import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/icon.png';

export default function ButtonAppBar() {
    const { user, logout, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const menuOpen = Boolean(menuAnchorEl);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const handleMenu = (event) => {
        if (isAuthenticated()) {
            setAnchorEl(event.currentTarget);
        } else {
            navigate('/login');
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuOpen = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleProfile = () => {
        navigate('/profile');
        handleClose();
    };

    const handleLogout = () => {
        logout();
        handleClose();
        navigate('/dashboard');
    };

    const menuItems = [
        { label: 'AI Inferencing', path: '/ai' },
        { label: 'Algo Trading Bots', path: '/algo-trading-bots' }
    ];

    const handleMenuItemClick = (path) => {
        if (path === '/ai' || path === '/algo-trading-bots') {
            if (isAuthenticated()) {
                navigate(path);
            } else {
                navigate('/login', { state: { from: path } });
            }
        } else {
            navigate(path);
        }
        handleMenuClose();
    };

    return (
        <AppBar position="static">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    ml: isDesktop ? 0 : 0 
                }}>
                    <img 
                        src={Logo} 
                        alt="SoozHub Logo" 
                        style={{ marginRight: 8, height: '40px', cursor: 'pointer' }} 
                        onClick={() => navigate('/')} 
                    />
                    <Typography 
                        variant="h6" 
                        component="div" 
                        sx={{ cursor: 'pointer' }} 
                        onClick={() => navigate('/')}
                    >
                        SoozHub
                    </Typography>
                </Box>
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mr: isDesktop ? 0 : 1
                }}>
                    <IconButton
                        size="small"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        aria-controls={menuOpen ? 'menu-appbar-hamburger' : undefined}
                        aria-haspopup="true"
                        onClick={handleMenuOpen}
                        sx={{ mr: 0.5 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="body2" sx={{ mx: 0.5, color: 'inherit' }}>
                        |
                    </Typography>
                    <IconButton
                        size="small"
                        edge="end"
                        color="inherit"
                        aria-label="account of current user"
                        aria-controls={open ? 'menu-appbar' : undefined}
                        aria-haspopup="true"
                        onClick={handleMenu}
                        sx={{ ml: 0.5 }}
                    >
                        {isAuthenticated() && user?.profilePicture ? (
                            <img 
                                src={user.profilePicture} 
                                alt="Profile" 
                                style={{ borderRadius: '50%', width: '24px', height: '24px' }} 
                            />
                        ) : (
                            <AccountCircle />
                        )}
                    </IconButton>
                    {isAuthenticated() && (
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleProfile}>Profile</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    )}
                    <Menu
                        id="menu-appbar-hamburger"
                        anchorEl={menuAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={menuOpen}
                        onClose={handleMenuClose}
                    >
                        {menuItems.map((item, index) => (
                            <MenuItem 
                                key={index}
                                onClick={() => handleMenuItemClick(item.path)}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}