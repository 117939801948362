import React, { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../AuthContext';
import { Select, MenuItem, TextField, IconButton, Box } from '@mui/material';
import { styled } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';

const API_URL = process.env.REACT_APP_API_URL;

const StyledSelect = styled(Select)(({ theme }) => ({
    '& .MuiSelect-select': {
        backgroundColor: '#1e1e1e',
        color: '#fff',
        borderRadius: '8px',
        padding: '8px 32px 8px 16px',
        fontSize: '0.875rem',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        '&:focus': {
            borderRadius: '8px',
            backgroundColor: '#1e1e1e',
        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    '& .MuiSelect-icon': {
        color: 'rgba(255, 255, 255, 0.5)'
    }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#1e1e1e',
        borderRadius: '16px',
        padding: '4px 16px',
        '&.Mui-focused': {
            boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)'
        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.1)',
        transition: 'border-color 0.2s'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.2)'
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4299e1 !important',
        borderWidth: '1px !important'
    },
    '& .MuiInputBase-input': {
        color: '#fff',
        '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.5)',
            opacity: 1
        }
    }
}));

const StyledSendButton = styled(IconButton)(({ theme }) => ({
    color: '#4299e1',
    padding: '8px',
    '&:hover': {
        backgroundColor: 'rgba(66, 153, 225, 0.1)'
    },
    '&.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.2)'
    }
}));

const AIPage = ({ setNavbarOptions }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedModel, setSelectedModel] = useState('phi');
    const [currentConversationId, setCurrentConversationId] = useState(null);
    const [error, setError] = useState(null);
    const messagesEndRef = useRef(null);
    const wsRef = useRef(null);
    const { token, user } = useAuth();

    // Clean up WebSocket connection
    const closeWebSocket = useCallback(() => {
        if (wsRef.current) {
            console.log('Closing WebSocket connection');
            wsRef.current.close();
            wsRef.current = null;
        }
    }, []);

    // Load conversation messages
    const loadMessages = useCallback(async (conversationId) => {
        try {
            const response = await axios.get(
                `${API_URL}/ai/conversations/${conversationId}/messages/`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setMessages(response.data.map(msg => ({
                role: msg.role,
                content: msg.content,
                status: msg.status,
                metadata: msg.metadata,
                isError: msg.status === 'failed'
            })));
        } catch (error) {
            console.error('Error loading messages:', error);
            setError('Failed to load messages');
        }
    }, [token]);

    // Handle conversation change
    const handleConversationChange = useCallback(async (conversationId) => {
        setCurrentConversationId(conversationId);
        setMessages([]);
        setError(null);
        setIsLoading(false);
        
        if (conversationId) {
            await loadMessages(conversationId);
        }
    }, [loadMessages]);

    // Load conversations list
    const loadConversations = useCallback(async () => {
        try {
            const response = await axios.get(`${API_URL}/ai/conversations/`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            setNavbarOptions({
                sections: [
                    {
                        title: 'Conversations',
                        items: [
                            {
                                id: 'new-chat',
                                label: 'New Chat',
                                selected: !currentConversationId,
                                onClick: () => handleConversationChange(null),
                                style: { cursor: 'pointer' }
                            }
                        ]
                    },
                    {
                        title: 'History',
                        items: response.data.map(chat => ({
                            id: chat.id,
                            label: chat.title || `Chat ${chat.id}`,
                            selected: chat.id === currentConversationId,
                            onClick: () => handleConversationChange(chat.id),
                            style: { cursor: 'pointer' }
                        }))
                    }
                ],
                onUpdate: loadConversations
            });
        } catch (error) {
            console.error('Error loading conversations:', error);
        }
    }, [token, currentConversationId, handleConversationChange]);

    // Send message
    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || isLoading || !user) return;

        const messageContent = newMessage.trim();
        setNewMessage('');
        setIsLoading(true);
        setError(null);

        // Add user message to UI immediately
        setMessages(prev => [...prev, {
            role: 'user',
            content: messageContent,
            status: 'pending'
        }]);

        try {
            // Send message
            const response = await axios.post(`${API_URL}/ai/chat/`, {
                message: messageContent,
                model: selectedModel,
                conversation_id: currentConversationId,
                message_metadata: {}
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const messageId = response.data.message_id;
            const conversationId = response.data.conversation_id;

            // Update the message with metadata
            setMessages(prev => prev.map(msg => {
                if (msg.role === 'user' && msg.content === messageContent && msg.status === 'pending') {
                    return {
                        ...msg,
                        metadata: { message_id: messageId, conversation_id: conversationId }
                    };
                }
                return msg;
            }));

            // Set conversation ID if this is a new chat
            if (!currentConversationId) {
                setCurrentConversationId(conversationId);
                loadConversations();
            }

            console.log('Message sent successfully:', {
                messageId,
                conversationId,
                content: messageContent,
                userId: user.id
            });

            // Close any existing WebSocket
            closeWebSocket();

            // Create new WebSocket connection
            console.log('Creating new WebSocket connection for message:', messageId);
            const wsBase = API_URL.replace('http://', 'ws://').replace('https://', 'wss://');
            const wsUrl = `${wsBase}/ai/ws/${user.id}?token=${encodeURIComponent(token)}`;
            console.log('WebSocket URL:', wsUrl);

            const ws = new WebSocket(wsUrl);
            wsRef.current = ws;

            ws.onopen = () => {
                console.log('WebSocket connection opened, waiting for response to message:', messageId);
            };

            ws.onmessage = async (event) => {
                console.log('Received WebSocket message:', event.data);
                const data = JSON.parse(event.data);

                if (data.type === 'token_expired') {
                    console.log('Token expired, closing connection');
                    setError('Session expired, please refresh the page');
                    setIsLoading(false);
                    closeWebSocket();
                    return;
                }
                
                if (data.type === 'message') {
                    console.log('Processing message:', data);
                    const messageData = data.data.message;
                    
                    if (messageData.metadata.parent_message_id === messageId) {
                        console.log('Received matching response for message:', messageId);
                        setMessages(prev => [...prev, {
                            role: 'assistant',
                            content: messageData.content,
                            status: 'completed',
                            metadata: messageData.metadata
                        }]);
                        setIsLoading(false);
                        closeWebSocket();
                    } else {
                        console.log('Received message for different request:', messageData.metadata.parent_message_id);
                    }
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
                setError('Connection error occurred');
                setIsLoading(false);
                closeWebSocket();
            };

            ws.onclose = (event) => {
                console.log('WebSocket connection closed with code:', event.code);
                if (event.code === 4003) {
                    setError('Authentication failed');
                } else if (event.code === 4001) {
                    setError('Session expired, please refresh the page');
                }
            };

        } catch (error) {
            console.error('Error sending message:', error);
            setIsLoading(false);
            
            // Handle the pending message error specifically
            if (error.response?.status === 409) {
                setError('Please wait for the previous message to be processed before sending a new one.');
                // Restore the message content so the user doesn't lose their input
                setNewMessage(messageContent);
            } else {
                setError(error.response?.data?.detail || 'Failed to send message');
            }
            
            closeWebSocket();
        }
    };

    // Cleanup on unmount
    useEffect(() => {
        return () => closeWebSocket();
    }, [closeWebSocket]);

    // Initial load of conversations
    useEffect(() => {
        loadConversations();
    }, [loadConversations]);

    // Auto-scroll to bottom when messages change
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (
        <div className="flex flex-col h-full bg-gray-800">
            {error && (
                <div className="bg-red-600/10 border border-red-600/20 text-red-500 px-4 py-2 mx-auto mt-4 rounded-md max-w-2xl w-full">
                    {error}
                </div>
            )}
            <div className="flex-1 overflow-y-auto p-6">
                <div className="max-w-2xl mx-auto space-y-6">
                    {messages.map((message, index) => (
                        <div 
                            key={index} 
                            className={`
                                flex w-full 
                                ${message.role === 'user' ? 'justify-end' : 'justify-start'}
                            `}
                        >
                            <div 
                                className={`
                                    relative px-4 py-3 
                                    ${message.role === 'user' 
                                        ? 'bg-blue-600 text-white ml-12' 
                                        : 'bg-gray-700 text-white mr-12'
                                    }
                                    max-w-[80%]
                                    ${message.role === 'user'
                                        ? 'rounded-t-xl rounded-l-xl'
                                        : 'rounded-t-xl rounded-r-xl'
                                    }
                                `}
                            >
                                <p className="text-[15px] leading-relaxed whitespace-pre-wrap">{message.content}</p>
                            </div>
                        </div>
                    ))}

                    {isLoading && (
                        <div className="flex justify-start w-full">
                            <div className="relative px-4 py-3 bg-gray-700 text-white mr-12 rounded-t-xl rounded-r-xl">
                                <div className="flex space-x-2">
                                    <div className="w-2 h-2 bg-white/40 rounded-full animate-bounce" 
                                         style={{ animationDelay: '0ms' }} />
                                    <div className="w-2 h-2 bg-white/40 rounded-full animate-bounce" 
                                         style={{ animationDelay: '150ms' }} />
                                    <div className="w-2 h-2 bg-white/40 rounded-full animate-bounce" 
                                         style={{ animationDelay: '300ms' }} />
                                </div>
                            </div>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
            </div>

            <Box
                sx={{
                    position: 'absolute',
                    bottom: '40px',
                    left: { xs: 0, sm: '16.666667%' },
                    right: 0,
                    bgcolor: 'background.paper',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Box sx={{ 
                    maxWidth: '900px', 
                    mx: 'auto', 
                    p: 3,
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                        <StyledSelect
                            value={selectedModel}
                            onChange={(e) => setSelectedModel(e.target.value)}
                            size="small"
                        >
                            <MenuItem value="any">Any Model</MenuItem>
                            <MenuItem value="phi">Phi-2</MenuItem>
                            <MenuItem value="mixtral">Mixtral</MenuItem>
                            <MenuItem value="llama2">Llama 2</MenuItem>
                            <MenuItem value="codellama">Code Llama</MenuItem>
                            <MenuItem value="mistral">Mistral</MenuItem>
                        </StyledSelect>
                    </Box>

                    <form onSubmit={handleSendMessage}>
                        <Box sx={{ position: 'relative' }}>
                            <StyledTextField
                                fullWidth
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="Type your message..."
                                variant="outlined"
                                disabled={isLoading}
                                InputProps={{
                                    endAdornment: (
                                        <StyledSendButton
                                            type="submit"
                                            disabled={!newMessage.trim() || isLoading}
                                            size="small"
                                        >
                                            <SendIcon />
                                        </StyledSendButton>
                                    )
                                }}
                            />
                        </Box>
                    </form>
                </Box>
            </Box>
        </div>
    );
};

export default AIPage; 