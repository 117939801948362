import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const PrivateRoute = ({ children, publicAccess }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();

    // For public routes or authenticated users, render the children
    if (publicAccess || isAuthenticated()) {
        return children;
    }

    // For private routes where user is not authenticated, redirect to login
    // Store the attempted location for redirecting back after login
    return <Navigate to="/login" state={{ from: location }} replace />;
};

export default PrivateRoute;
